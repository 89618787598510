import { getAuthorizedConfig, getMockConfig } from '../utils/apiUtils';
import { TOKEN_KEY } from '../constants/storageConstants';

const removeLoginInformation = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.clear();
  window.location = process.env.BASE_URL;
};

const handleRequest = async (request) => {
  // handle unaothirzed responses by removing the access token and going back to the login page
  try {
    const response = await request();
    return response;
  } catch (err) {
    if (err.response.status === 403 || err.response.status === 401) {
      removeLoginInformation();
    }
    throw err;
  }
};

export default class A2CAAuthorizedClient {
  constructor(query, instance) {
    if (instance) {
      this.agent = instance;
    } else if (query?.mockAccountId) {
      const mockInstance = getMockConfig(query.mockAccountId);
      this.agent = mockInstance;
    } else {
      const defaultInstance = getAuthorizedConfig();
      this.agent = defaultInstance;
    }
  }

  async getCurrentAccount() {
    const response = await handleRequest(() => this.agent.get('account'));
    return response.data;
  }

  async getDownloadState() {
    const response = await handleRequest(() => this.agent.get('account/state'));
    return response.data;
  }

  async getCampaigns(advertisingScope, region, stateFilter = 'ENABLED,PAUSED,ARCHIVED', includeNotDelivering = true) {
    const response = await handleRequest(() => this.agent.get('campaign', {
      params: {
        advertisingScope, region, stateFilter, includeNotDelivering,
      },
    }));
    return response.data;
  }

  async getCampaignsWithMetricData(advertisingScope, region, stateFilter = 'ENABLED,PAUSED,ARCHIVED') {
    const response = await handleRequest(() => this.agent.get('campaign/metrics', {
      params: { advertisingScope, region, stateFilter },
    }));
    return response.data;
  }

  async getCampaignsWithState(advertisingScope, region, stateFilter = 'ENABLED,PAUSED,ARCHIVED') {
    const response = await handleRequest(() => this.agent.get('campaign/state', {
      params: { advertisingScope, region, stateFilter },
    }));
    return response.data;
  }

  async getCampaign(advertisingScope, region, campaignId) {
    const response = await handleRequest(() => this.agent.get(`campaign/${campaignId}`, {
      params: { advertisingScope, region },
    }));
    return response.data;
  }

  async getAdGroups(advertisingScope, region, campaignId) {
    const response = await handleRequest(() => this.agent.get('adgroup', {
      params: {
        advertisingScope, region, campaignId,
      },
    }));
    return response.data;
  }

  async getAllAdGroups(advertisingScope, region) {
    const response = await handleRequest(() => this.agent.get('adgroup/all', {
      params: {
        advertisingScope, region,
      },
    }));
    return response.data;
  }

  async getAdGroup(advertisingScope, region, adGroupId) {
    const response = await handleRequest(() => this.agent.get(`adgroup/${adGroupId}`, {
      params: { advertisingScope, region },
    }));
    return response.data;
  }

  async getKeywords(advertisingScope, region, adGroupId) {
    const response = await handleRequest(() => this.agent.get('keyword', {
      params: { advertisingScope, region, adGroupId },
    }));
    return response.data;
  }

  async getNegativeKeywords(advertisingScope, region, campaignId) {
    const response = await handleRequest(() => this.agent.get('keyword/negative', {
      params: { advertisingScope, region, campaignId },
    }));
    return response.data;
  }

  async getKeywordBidRecommendation(advertisingScope, region, campaignId, adGroupId, keywordValue, keywordType) {
    const response = await handleRequest(() => this.agent.get(`keyword/bid/${campaignId}/${adGroupId}`, {
      params: {
        advertisingScope, region, keywordType, keywordValue,
      },
    }));
    return response.data;
  }

  async getReports(advertisingScope, region, reportType, from, to, filterById = null, filterType = null, campaignId = null) {
    const response = await handleRequest(() => this.agent.get('reports', {
      params: {
        advertisingScope,
        region,
        reportType,
        from,
        to,
        filteredId: filterById,
        filteredType: filterType,
        campaignId,
      },
    }));

    return response.data;
  }

  async getSummary(advertisingScope, region) {
    const response = await handleRequest(() => this.agent.get('reports/summary', {
      params: {
        advertisingScope,
        region,
      },
    }));

    return response.data;
  }

  async getGeneralChartData(advertisingScope, region, reportType, from, to, filterById = null, filterType = null, campaignId = null) {
    const response = await handleRequest(() => this.agent.get('reports/chart', {
      params: {
        advertisingScope,
        region,
        reportType,
        from,
        to,
        filterById,
        filterType,
        campaignId,
      },
    }));

    return response.data;
  }

  async getCampaignChartData(advertisingScope, region, reportType, from, to, enabledCampaignIds, campaignId = null) {
    const response = await handleRequest(() => this.agent.post('reports/chart/campaign', { enabledCampaignIds }, {
      params: {
        advertisingScope,
        region,
        reportType,
        from,
        to,
        campaignId,
      },
    }));

    return response.data;
  }

  async sendEmail(subject, message) {
    const response = await handleRequest(() => this.agent.post('account/email', { subject, message }));

    return response.data;
  }

  async downloadReports(advertisingScope, region, reportType, from, to) {
    const response = await handleRequest(() => this.agent.get('reports/download', {
      params: {
        advertisingScope,
        region,
        reportType,
        from,
        to,
      },
    }));

    return response.data;
  }

  async getReportsStatus(advertisingScope, region) {
    const response = await handleRequest(() => this.agent.get('reports/status', {
      params: {
        advertisingScope,
        region,
      },
    }));

    return response.data;
  }

  async getProfileSettings(profileId) {
    const response = await handleRequest(() => this.agent.get(`account/settings/profile/${profileId}`));
    return response.data;
  }

  async updateProfileSettings(profileId, settings) {
    const response = await handleRequest(() => this.agent.put(`account/settings/profile/${profileId}`, settings));
    return response.data;
  }

  async disconnectAccount() {
    const response = await handleRequest(() => this.agent.post('account/disconnect', {}));
    return response.data;
  }

  async getProductAds(advertisingScope, region, adGroupId) {
    const response = await handleRequest(() => this.agent.get('productad', {
      params: { advertisingScope, region, adGroupId },
    }));
    return response.data;
  }

  async createBidRule(advertisingScopeId, region, bidRule) {
    const response = await handleRequest(() => this.agent.post('bidrule', {
      advertisingScopeId, ...bidRule, region,
    }));
    return response.data;
  }

  async updateBidRule(advertisingScopeId, region, bidRule, bidRuleId) {
    const response = await handleRequest(() => this.agent.put(`bidrule/${bidRuleId}`, {
      advertisingScopeId, region, ...bidRule,
    }));
    return response.data;
  }

  async patchBidRules(patches) {
    const response = await handleRequest(() => this.agent.patch('bidrule/patch', {
      patches,
    }));
    return response.data;
  }

  async deleteBidRule(bidRuleId, advertisingScopeId) {
    const response = await handleRequest(() => this.agent.delete(`bidrule/${bidRuleId}`, {
      params: {
        advertisingScopeId,
      },
    }));
    return response.data;
  }

  async getBidRules(advertisingScopeId) {
    const response = await handleRequest(() => this.agent.get('bidrule', {
      params: {
        advertisingScopeId,
      },
    }));
    return response.data;
  }

  async generateBidRules(advertisingScopeId, region, campaignTargets, allCampaignsSelected) {
    const response = await handleRequest(() => this.agent.post('bidrule/generate', {
      advertisingScopeId, region, campaignTargets, allCampaignsSelected,
    }));
    return response.data;
  }

  async getTargets(advertisingScope, region, adGroupId) {
    const response = await handleRequest(() => this.agent.get('target', {
      params: { advertisingScope, region, adGroupId },
    }));
    return response.data;
  }

  async executeBidRules(advertisingScopeId, region) {
    const response = await handleRequest(() => this.agent.post('bidrule/execute', null, {
      params: { advertisingScopeId, region },
    }));
    return response.data;
  }

  async updateMaxBid(advertisingScopeId, maxBid) {
    const response = await handleRequest(() => this.agent.put(`bidrule/settings/${advertisingScopeId}`, {
      maxBid,
    }));
    return response.data;
  }

  async getLatestBidRuleHistory({
    advertisingScopeId, region, page = { page: 0, size: 15, sort: undefined }, campaignId, date,
  }) {
    const response = await handleRequest(() => this.agent.get('bidrule/history/latest', {
      params: {
        advertisingScopeId,
        region,
        ...page,
        campaignId,
        from: date,
      },
    }));
    return response.data;
  }

  async getCampaignsInHistory(advertisingScopeId, region) {
    const response = await handleRequest(() => this.agent.get('bidrule/history/campaigns', {
      params: {
        advertisingScopeId,
        region,
      },
    }));

    return response.data;
  }

  async updateEnabledProfiles(enabledProfiles) {
    const response = await handleRequest(() => this.agent.put('account/profiles', {
      enabledProfiles,
    }));
    return response.data;
  }

  // METRICS
  async getProfileMetrics(advertisingScopeId) {
    const response = await handleRequest(() => this.agent.get('metrics', {
      params: {
        advertisingScopeId,
      },
    }));
    return response.data;
  }

  async countProfileMetrics(advertisingScopeId) {
    const response = await handleRequest(() => this.agent.get('metrics/count', {
      params: {
        advertisingScopeId,
      },
    }));
    return response.data;
  }

  async createMetric(advertisingScopeId, metric) {
    const response = await handleRequest(() => this.agent.post('metrics',
      metric,
      {
        params: {
          advertisingScopeId,
        },
      }));
    return response.data;
  }

  async deleteMetric(metricId) {
    const response = await handleRequest(() => this.agent.delete('metrics',
      {
        params: {
          metricId,
        },
      }));
    return response.data;
  }

  async updateMetric(advertisingScopeId, metric) {
    const response = await handleRequest(() => this.agent.put('metrics', metric, {
      params: {
        advertisingScopeId,
      },
    }));
    return response.data;
  }

  async getBidRuleTemplates() {
    const response = await handleRequest(() => this.agent.get('bidrule/template', {}));
    return response.data;
  }

  async changePassword(newPassword) {
    const response = await handleRequest(() => this.agent.post('account/password/change', { newPassword }, {}));

    return response.data;
  }

  async getPlanInformation() {
    const response = await handleRequest(() => this.agent.get('pricing/plan', {}));
    return response.data;
  }

  async getCurrentSubscription() {
    const response = await handleRequest(() => this.agent.get('billing/subscription', {}));
    return response.data;
  }

  async buyPlan(plan, autoUpgrade, discountCode, selectedProfiles) {
    const response = await handleRequest(() => this.agent.post(`pricing/plan/${plan}`, {
      autoUpgrade,
      discountCode,
      profilesToUpdate: selectedProfiles,
    }));
    return response.data;
  }

  async cancelPlan() {
    const response = await handleRequest(() => this.agent.post('pricing/plan/cancel', {}));
    return response.data;
  }

  async getBilling() {
    const response = await handleRequest(() => this.agent.get('billing', {}));
    return response
      .data;
  }

  async getAutoUpgrade() {
    const response = await handleRequest(() => this.agent.get('account/settings/auto-upgrade', {
    }));
    return response.data;
  }

  async updateAutoUpgrade(autoUpgrade) {
    const response = await handleRequest(() => this.agent.post(`account/settings/auto-upgrade/${autoUpgrade}`, {
    }));
    return response.data;
  }

  // ADMIN
  async getAdminAccounts(emailSearch) {
    const response = await handleRequest(() => this.agent.get('admin/accounts', {
      params: {
        search: emailSearch,
      },
    }));
    return response.data;
  }

  async getAdminAccountsStatistics(accounts) {
    const ids = accounts.map((account) => account.id);
    const response = await handleRequest(() => this.agent.post('admin/accounts/statistics', {
      accountIds: ids,
    }));
    return response.data;
  }

  async deleteAccount(accountId) {
    const response = await handleRequest(() => this.agent.delete(`admin/account/${accountId}`, {
      params: {
      },
    }));
    return response.data;
  }

  async getAccountProducts(accountId) {
    const response = await handleRequest(() => this.agent.get(`admin/productAds/${accountId}`, {
      params: {
      },
    }));
    return response.data;
  }

  async getAccountProfiles(accountId) {
    const response = await handleRequest(() => this.agent.get(`admin/profiles/${accountId}`, {
      params: {
      },
    }));
    return response.data;
  }

  async deleteAccountProfiles(selectedAccount, profileIds, forceDeletion) {
    const response = await handleRequest(() => this.agent.delete('admin/profiles', {
      data: {
        accountId: selectedAccount.id,
        profileIds,
        forceDeletion,
      },
    }));
    return response.data;
  }

  async getAccountInvitations(emailSearch = '') {
    const response = await handleRequest(() => this.agent.get('admin/invitation', {
      params: {
        search: emailSearch,
      },
    }));
    return response.data;
  }

  async generateAccountInvitation(email) {
    const response = await handleRequest(() => this.agent.post('admin/invitation/generate', {
      email,
    }));
    return response.data;
  }

  async resendAccountInvitation(email) {
    const response = await handleRequest(() => this.agent.post('admin/invitation/resend', {
      email,
    }));
    return response.data;
  }

  async generateBidRuleExecutionHistoryReport() {
    const response = await handleRequest(() => this.agent.post('admin/history', {
    }));
    return response.data;
  }

  async getAllDiscounts() {
    const response = await handleRequest(() => this.agent.get('discount/all', {
      params: {
      },
    }));
    return response.data;
  }

  async createDiscount(code, description, value, duration, redeemBefore) {
    const response = await handleRequest(() => this.agent.post('discount', {
      code,
      description,
      duration,
      expirationDate: redeemBefore,
      value: {
        value, type: 'PERCENTAGE',
      },
      affectedAccounts: null,
      type: 'DEFAULT',
    }));
    return response.data;
  }

  async cancelDiscount(discountId) {
    const response = await handleRequest(() => this.agent.delete(`discount/${discountId}`, {
    }));
    return response.data;
  }

  async retrieveDiscount(discountCode, amount) {
    const response = await handleRequest(() => this.agent.get(`discount?discountCode=${discountCode}&amount=${amount}`));
    return response.data;
  }
}
