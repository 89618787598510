<template>
  <span
    :hidden="true"
  >{{ refreshCounter }}</span>
  <div class="layout-topbar">
    <router-link
      v-if="checkAccountEnabled"
      :to="{name: 'dashboard', query}"
      class="layout-topbar-logo"
    >
      <img
        alt="Logo"
        :src="topBarImage"
        class="logo-image"
      >
      <AppBrandName />
    </router-link>
    <router-link
      v-else
      :to="{name: 'subscribe', query}"
      class="layout-topbar-logo"
    >
      <img
        alt="Logo"
        :src="topBarImage"
      >
      <AppBrandName />
    </router-link>

    <button
      v-styleclass="{
        selector: '@next',
        enterClass: 'hidden',
        enterActiveClass: 'scalein',
        leaveToClass: 'hidden',
        leaveActiveClass: 'fadeout',
        hideOnOutsideClick: true,
      }"
      class="p-link layout-topbar-menu-button layout-topbar-button"
    >
      <i class="pi pi-ellipsis-v" />
    </button>

    <ul class="layout-topbar-menu hidden lg:flex origin-top">
      <li v-if="accountType === 'ADMIN' && checkAccountEnabled">
        <Button
          type="button"
          class="mr-2"
          label="Administration"
          :loading="loading"
          @click="goToAdminDashboard"
        />
      </li>
      <li
        v-if="accountLinked && checkAccountEnabled"
      >
        <label
          for="profileAutocomplete"
          class="pr-2"
        >Select Amazon Ads Profile:</label>
        <AutoComplete
          id="profileAutocomplete"
          v-model="searchQuery"
          :field="getProfileLabel"
          dropdown
          placeholder="Select Amazon Ads Profile.."
          :suggestions="filteredProfiles"
          :disabled="!accountLinked"
          @item-select="select"
          @complete="search"
          @focus="onFocus"
          @blur="onBlur"
        />
      </li>
      <li v-else>
        <Button
          v-if="!accountLinked && checkAccountEnabled"
          label="Log In With Amazon"
          class="p-button-rounded"
          :loading="loading"
          @click="loginWithAmazonHandler"
        >
          <img
            src="https://images-na.ssl-images-amazon.com/images/G/01/lwa/btnLWA_drkgry_32x32.png"
            alt="Log In With Amazon button"
          >
          <span class="ml-2 font-bold">Log In With Amazon</span>
        </Button>
      </li>
      <li>
        <button
          class="p-link layout-topbar-button"
          @click="toggle"
        >
          <i class="pi pi-user" />
          <span>Profile</span>
        </button>

        <OverlayPanel
          ref="accountOverlay"
          class="text-center"
          append-to="body"
        >
          <p class="text-lg font-bold">
            My Account
          </p>
          <p>{{ username }}</p>
          <p>{{ email }}</p>
          <p>{{ formattedAccountType }}</p>
          <router-link :to="{name: 'myAccountInfo', query}">
            <Button
              label="Account Management"
              class="p-button-rounded  p-button-info"
            />
          </router-link>
          <div v-if="accountLinked && checkAccountEnabled">
            <Divider layout="horizontal" />
            <Button

              label="Unlink Amazon Account"
              class="p-button-rounded"
              :loading="loading"
              @click="unlinkAmazonHandler"
            >
              <span class="ml-2 font-bold">Unlink Amazon Account</span>
            </Button>
          </div>
          <div>
            <Divider layout="horizontal" />
            <Button
              label="Logout"
              class="p-button-rounded w-full p-button-secondary"
              @click="logoutHandler"
            />
          </div>
        </OverlayPanel>
      </li>
    </ul>
  </div>
</template>

<script>
import { useStore, mapMutations, mapGetters } from 'vuex';
import { computed, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { findAccountByType } from './constants/accountConstants';
import A2CAClient from './api/a2caClient';
import A2CAAuthorizedClient from './api/a2caAuthorizedClient';
import { SELECTED_PROFILE_KEY } from './constants/storageConstants';
import { isAccountEnabled } from './utils/accountUtils';
import AppBrandName from './components/AppBrandName.vue';

const getStoredProfile = (selectedProfile, account, useSession = false) => {
  if (selectedProfile) {
    return selectedProfile;
  }

  let storage;
  if (useSession) {
    storage = sessionStorage;
  } else {
    storage = localStorage;
  }

  const storedProfile = storage.getItem(SELECTED_PROFILE_KEY);

  if (storedProfile !== null && storedProfile !== '{}') {
    return JSON.parse(storedProfile)[account.id];
  }
  return null;
};

export default {
  components: { AppBrandName },
  inject: ['query'],
  props: {
    refreshCounter: {
      type: Number,
      required: true,
    },
  },
  setup() {
    const store = useStore();
    const { account } = store.state.auth;
    const setSelectedProfile = (payload) => {
      store.commit('setSelectedProfile', payload);
    };

    const { getters } = useStore();
    const { selectedProfile } = getters;
    const { query, path } = useRoute();
    const { push } = useRouter();

    const getProfileLabel = (profile) => `${profile.name} - ${profile.countryCode}`;

    const loading = ref(false);
    const searchQuery = ref(selectedProfile ? getProfileLabel(selectedProfile) : '');

    const formattedAccountType = computed(() => findAccountByType(store.getters.accountType)?.name ?? '');

    const profiles = ref();
    const filteredProfiles = ref();

    const search = (event) => {
      const clonedItems = [...profiles.value];
      filteredProfiles.value = event.query ? clonedItems.filter((profile) => profile.name.toLowerCase().includes(event.query.toLowerCase())) : clonedItems;
    };

    const select = (event) => {
      setSelectedProfile({ profile: event.value, useSession: !!query?.mockAccountId });
      if (path !== '/settings/profiles') {
        push({ name: 'home', query });
      }
    };

    const persistedProfile = getStoredProfile(store.state.auth.selectedProfile, account, !!query?.mockAccountId);
    setSelectedProfile({ profile: persistedProfile, useSession: !!query?.mockAccountId });

    const topBarImage = '/images/logo.png';

    const logoutHandler = () => {
      store.commit('logout');
      push({ name: 'login' });
    };

    const checkAccountEnabled = computed(() => isAccountEnabled(account));
    const goToAdminDashboard = () => push({ path: '/admin' });
    const cleanSearch = () => { searchQuery.value = ''; };
    const resetSearch = () => { searchQuery.value = selectedProfile ? getProfileLabel(selectedProfile) : ''; };
    return {
      userId: account.id,
      topBarImage,
      profiles,
      filteredProfiles,
      loading,
      formattedAccountType,
      goToAdminDashboard,
      logoutHandler,
      checkAccountEnabled,
      getProfileLabel,
      search,
      select,
      selectedProfile,
      onFocus: cleanSearch,
      onBlur: resetSearch,
      searchQuery,
    };
  },
  computed: { ...mapGetters(['enabledProfiles', 'accountLinked', 'username', 'email', 'accountType']) },
  watch: {
    refreshCounter: {
      handler(val, oldVal) {
        if (val > oldVal) {
          this.selectedProfile = getStoredProfile(null, this.$store.state.auth.account, !!this.query?.mockAccountId);
        }
      },
    },
    enabledProfiles: {
      handler(val) {
        const activeProfileIds = Object.entries(val).filter((idEntry) => idEntry[1]).map((idEntry) => idEntry[0]);
        const res = this.$store.state.auth.profiles.filter((profile) => activeProfileIds.includes(profile.profileId));
        this.profiles = res;
      },
    },
  },

  methods: {
    toggle(event) {
      this.$refs.accountOverlay.toggle(event);
    },
    async loginWithAmazonHandler() {
      const client = new A2CAClient();
      this.loading = true;
      const authorizationUrl = await client.getAuthorizationUrl(this.userId);
      this.loading = false;
      window.location = authorizationUrl;
    },
    async unlinkAmazonHandler() {
      const client = new A2CAAuthorizedClient(this.query);
      this.loading = true;
      const response = await client.disconnectAccount();

      this.setAccount(response);
      this.setEnabledProfiles([]);
      this.setSelectedProfile({ profile: null, useSession: !!this.query?.mockAccountId });
      this.selectedProfile = null;
      this.loading = false;
      this.$refs.accountOverlay.toggle();
      this.$router.push({ name: 'home' });
    },
    ...mapMutations(['setAccount', 'setEnabledProfiles', 'setSelectedProfile']),
  },
};
</script>
<style scoped>
  .logo-image {
    height: 2rem !important;
  }
</style>
