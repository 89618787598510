<template>
  <div>
    <div @click="toggleSidebar">
      <i
        class="pi pi-question-circle text-1xl toggle-icon"
      /> <span class="ml-2"> Need Help?</span>
    </div>

    <!-- Sidebar -->
    <div
      v-if="isSidebarOpen"
      class="fixed support-container bg-white shadow-lg z-50 overflow-y-auto p-4"
      :class="{'translate-x-full': !isSidebarOpen}"
    >
      <!-- Close Button -->
      <Button
        class="p-button-danger menu-config-close p-button-rounded p-button-text absolute top-0 right-0"
        icon="pi pi-times"
        :style="{ 'z-index': 1 }"
        @click="toggleSidebar"
      />

      <!-- Search Section -->
      <h3>Need help?</h3>
      <span>Search our knowledge base</span>
      <div class="mt-3 mb-6">
        <InputText
          v-model="searchTerm"
          class="w-full"
        />

        <div
          v-if="searchResults.length"
          class="space-y-2"
        >
          <div
            v-for="result in searchResults"
            :key="result.id"
            class="p-2 border rounded cursor-pointer hover:bg-gray-100 pt-5"
            @click="navigateToResult(result)"
          >
            <h3 class="text-xl">
              {{ result.title }}
            </h3>
            <p>
              {{ result.snippet }}
            </p>
          </div>
        </div>
      </div>

      <div
        v-if="!searchResults.length"
        class="w-full border-t pt-3 email-container flex flex-column"
      >
        <h4>Contact support</h4>
        <span class="mb-3">We are here to help!</span>
        <InputText
          v-model="account"
          :value="emailFrom()"
          disabled="true"
          class="w-full p-2 border rounded mb-2"
        />
        <InputText
          v-model="supportSubject"
          placeholder="Email Subject"
          class="w-full p-2 border rounded mb-2"
        />
        <Textarea
          v-model="supportMessage"
          placeholder="Your message..."
          class="w-full p-2 border rounded mb-2 h-15rem"
        />

        <Button
          type="button"
          class="mr-2"
          label="Contact us"
          :disabled="!(supportMessage?.trim() && supportSubject?.trim())"
          @click="sendSupportEmail"
        />
        <p
          v-if="emailStatus"
          :class="{
            'error-text': emailStatus === 'error'
          }"
        >
          {{ emailStatusMessage }}
        </p>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';

import algoliasearch from 'algoliasearch';
import A2CAClient from '../api/a2caAuthorizedClient';

// Search functionality
const searchTerm = ref('');
const searchResults = ref([]);
const isSidebarOpen = ref(false);

// Support email functionality
const supportSubject = ref('');
const supportMessage = ref('');
const emailStatus = ref(null);
const emailStatusMessage = ref('');

const client = algoliasearch('SXLBLU3EA0', '793ecaeb38f24377383b9046083d15aa');
const index = client.initIndex('articles');

const store = useStore();
const route = useRoute();
const { account } = store.state.auth;

// Debounced search function
let searchTimeout = null;
const debouncedSearch = () => {
  clearTimeout(searchTimeout);
  if (searchTerm.value.trim().length > 2) {
    searchTimeout = setTimeout(async () => {
      try {
        const { hits } = await index.search(searchTerm.value.trim());
        searchResults.value = hits.map((hit) => ({
          id: hit.objectID,
          title: hit.title,
          snippet: hit.summary,
          url: hit.url,
        }));
      } catch (error) {
        console.error('Search error:', error);
      }
    }, 300);
  } else {
    searchResults.value = [];
  }
};

const emailFrom = () => `${account.name} <${account.email}>`;

watch([searchTerm], debouncedSearch);

const toggleSidebar = () => {
  isSidebarOpen.value = !isSidebarOpen.value;
};

const navigateToResult = (result) => {
  window.open(result.url, '_blank');
};
const sendSupportEmail = async () => {
  try {
    const a2cacClient = new A2CAClient(route.query);
    await a2cacClient.sendEmail(supportSubject.value, supportMessage.value);

    emailStatus.value = 'sent';
    emailStatusMessage.value = 'Thank you for reaching out! We\'ll be in touch shortly';
    supportSubject.value = '';
    supportMessage.value = '';
  } catch (error) {
    console.error('Email send error:', error);
    emailStatus.value = 'error';
    emailStatusMessage.value = 'Sorry we could not send your email';
  }
};

</script>
<style>
.toggle-icon {
  margin-left: 0.2rem;
}
.email-container {
  border-top: 1px solid rgb(206, 212, 218);
}
</style>
